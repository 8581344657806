import React from "react";
import { GoogleAuthProvider, getAuth, signInWithPopup, User } from "firebase/auth";
import firebase_app from "../firebase";

const auth = getAuth(firebase_app);

const LoginPage: React.FC<{ setUser: (user: User | null) => void }> = ({ setUser }) => {
  const signInWithGoogle = async () => {
    try {
      const provider = new GoogleAuthProvider();
      signInWithPopup(auth, provider)
        .then((result) => setUser(result.user))
        .catch((error) => console.error("Sign-in error:", error));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      {/* Logo */}
      <img src="/ai-companion-logo.png" alt="AI Companion Logo" className="w-40 mb-4" />

      <p className="text-gray-700 mb-6 text-center max-w-md">
        Your intelligent assistant for productivity and collaboration.
      </p>
      <button
        onClick={signInWithGoogle}
        className="px-6 py-3 bg-blue-500 text-white rounded-lg shadow-lg hover:bg-blue-600 transition"
      >
        Sign in with Google
      </button>
    </div>
  );
};

export default LoginPage;
