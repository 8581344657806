import { initializeApp, getApps, getApp} from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCqS5xRVPNZJZG1EpiYAaYCwHVMyluDAKM",
    authDomain: "ai-companion-a172e.firebaseapp.com",
    projectId: "ai-companion-a172e",
    storageBucket: "ai-companion-a172e.firebasestorage.app",
    messagingSenderId: "471983566166",
    appId: "1:471983566166:web:40937f60987f7550c3baf7",
    measurementId: "G-ZZC3RQKCRB"
  };

const firebase_app = getApps().length ? getApp() : initializeApp(firebaseConfig);
const analytics = getAnalytics(firebase_app);
export const auth = getAuth(firebase_app);
export default firebase_app;
