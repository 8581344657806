import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { User, onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase"; // Ensure correct import path
import "./App.css";
import EmailHomePage from "./components/EmailHomePage";
import LoginPage from "./components/LoginPage";
import HomePage from "./components/HomePage"; 

const App: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  // Check user authentication state when app loads
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (loggedInUser) => {
      setUser(loggedInUser);
      setLoading(false);
    });

    return () => unsubscribe(); // Cleanup function
  }, []);

  if (loading) return <div>Loading...</div>; // Show loading screen while checking auth state

  return (
    <Router>
      <Routes>
        <Route path="/" element={user ? <Navigate to="/home" /> : <LoginPage setUser={setUser} />} />
        <Route path="/email" element={user ? <EmailHomePage user={user} /> : <Navigate to="/" />} />
        <Route path="/home" element={user ? <HomePage user={user} /> : <Navigate to="/" />} />
      </Routes>
    </Router>
  );
};

export default App;
