import React, { useEffect, useState } from "react";

interface Email {
  From: string;
  Subject: string;
  Date: string;
}

const extractNameAndEmail = (from: string | undefined) => {
  if (!from) return { name: "Unknown Sender", email: "N/A" };
  const match = from.match(/(.*)<(.*)>/);
  return match ? { name: match[1].trim(), email: match[2].trim() } : { name: from, email: from };
};

const formatDate = (dateString: string): string =>
  new Date(dateString).toLocaleString("en-US", {
    weekday: "short",
    month: "short",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

const EMAIL_API_URL = "https://h03bx8l5ni.execute-api.us-east-1.amazonaws.com/default/readS3AndExposeEmails";

const EmailList: React.FC = () => {
  const [emails, setEmails] = useState<Email[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchEmails = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(EMAIL_API_URL);
      const data = await response.json();
      const sortedEmails = data.sort((a: Email, b: Email) => new Date(b.Date).getTime() - new Date(a.Date).getTime());
      setEmails(sortedEmails);
    } catch (err) {
      setError("Failed to fetch emails");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEmails();
  }, []);

  return (
    <div className="p-4 bg-white shadow-md rounded-lg">
      {/* Refresh Button */}
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-bold">...</h2>
        <button 
          onClick={fetchEmails}
          className="p-2 rounded-full bg-gray-200 hover:bg-gray-300 transition"
          title="Refresh"
        >
          <img src="/icons/refresh.svg" alt="Refresh" className="w-6 h-6" />
        </button>
      </div>

      {/* Email Table */}
      {loading ? (
        <p>Loading emails...</p>
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : (
        <table className="w-full border border-gray-300">
          <thead className="bg-gray-200">
            <tr>
              <th className="py-2 px-4 border">From</th>
              <th className="py-2 px-4 border">Subject</th>
              <th className="py-2 px-4 border">Date</th>
            </tr>
          </thead>
          <tbody>
            {emails.map((email, index) => {
              const { name, email: emailAddr } = extractNameAndEmail(email.From);
              return (
                <tr key={index} className="hover:bg-gray-100">
                  <td className="py-2 px-4 border">
                    <span className="relative group">
                      {name}
                      <span className="absolute left-0 bottom-6 hidden group-hover:block bg-gray-800 text-white text-xs rounded px-2 py-1">
                        {emailAddr}
                      </span>
                    </span>
                  </td>
                  <td className="py-2 px-4 border">{email.Subject}</td>
                  <td className="py-2 px-4 border">{formatDate(email.Date)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default EmailList;
