import React, { useState } from "react";
import { auth } from "../firebase"; // Ensure correct path
import { signOut } from "firebase/auth";
import EmailList from "./EmailList";
import Sidebar from "./Sidebar"; // Import Sidebar component

const EmailHomePage: React.FC<{ user: any }> = ({ user }) => {
  const [refreshKey, setRefreshKey] = useState(0);

  return (
    <div className="flex h-screen">
      {/* Sidebar */}
      <Sidebar />

      {/* Main Content */}
      <div className="flex-1 p-6">
        {/* Header */}
        <header className="flex justify-between items-center border-b pb-4 mb-4">
          <h1 className="text-2xl font-bold">Email</h1>
          <div className="flex items-center space-x-4">
            <span>{user?.displayName || "User"}</span>
            <button
              onClick={() => signOut(auth)}
              className="px-4 py-2 bg-red-500 text-white rounded shadow"
            >
              Sign Out
            </button>
          </div>
        </header>

        {/* Email List */}
        <EmailList key={refreshKey} />
      </div>
    </div>
  );
};

export default EmailHomePage;
