import React, { useState } from "react";
import Sidebar from "./Sidebar"; // Import the reusable Sidebar

const HomePage: React.FC<{ user: any }> = ({ user }) => {
    const [messages, setMessages] = useState<string[]>([]);
    const [input, setInput] = useState("");
  
    const getSessionId = () => {
        const today = new Date();
        return today.toISOString().split("T")[0]; // Extract YYYY-MM-DD
    };

  const handleSend = async () => {
    if (!input.trim()) return;
    // Generate session ID as today's date (YYYY-MM-DD)
 
    const sessionId = getSessionId();
    const userId = user?.uid || "guest";
    // Append user message
    setMessages((prev) => [`You: ${input}`, ...prev]);

    try {
      // Mock API call (replace with actual API request)"query": "Tell me how how Techcorp could benefit from ai",
    //"session_id": "123457",
    //"user_id": "ubser_abc"
      const response = await fetch("https://9peoy6oak6.execute-api.us-east-1.amazonaws.com/", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ query: input, session_id: sessionId, user_id: userId }),
      });
      const data = await response.json();
      let parsedBody:any;
      try {
        parsedBody = JSON.parse(data.body); // ✅ Parse `data.body`
      } catch (error) {
        console.error("Error parsing body:", error);
        parsedBody = { response: "Error processing response." };
      }

      // Append API response
      setMessages((prev) => [`AI: ${parsedBody.response}`, ...prev]);
    } catch (error) {
      setMessages((prev) => [`AI: Error fetching response`, ...prev]);
    }

    setInput(""); // Clear input field
  };

  return (
    <div className="flex h-screen">
      {/* Sidebar */}
      <Sidebar />

      {/* Main Content */}
      <div className="flex-1 flex flex-col p-6">
        <h1 className="text-2xl font-bold mb-4">Welcome to AI Companion</h1>
        <p className="mb-4"></p>

        {/* Chat Box */}
        <div className="flex-1 overflow-auto bg-gray-100 p-4 rounded shadow-inner">
          {messages.map((msg, index) => (
            <div key={index} className="mb-2">
              {msg}
            </div>
          ))}
        </div>

        {/* Input Box (Sticky at Bottom) */}
        <div className="mt-4 p-2 border-t bg-white flex">
          <input
            type="text"
            className="flex-1 p-2 border rounded mr-2"
            placeholder="Ask anything..."
            value={input}
            onChange={(e) => setInput(e.target.value)}
          />
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded"
            onClick={handleSend}
          >
            Send
          </button>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
