import React from "react";
import { Link } from "react-router-dom";

const Sidebar: React.FC = () => {
  return (
    <aside className="w-64 bg-gray-800 text-white p-4 h-screen">
      <h2 className="text-xl font-bold mb-4">AI Companion</h2>
      <ul className="space-y-2">
        <li className="p-2 rounded hover:bg-gray-700">
          <Link to="/home">Dashboard</Link>
        </li>
        <li className="p-2 rounded hover:bg-gray-700">
          <Link to="/email">Email</Link>
        </li>
        <li className="p-2 rounded hover:bg-gray-700">Task</li>
        <li className="p-2 rounded hover:bg-gray-700">Planner</li>
        
        <li className="p-2 rounded hover:bg-gray-700">Settings</li>
      </ul>
    </aside>
  );
};

export default Sidebar;
